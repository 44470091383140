@tailwind base;
@tailwind components;
@tailwind utilities;
@import "semantic-ui-css/semantic.min.css";
@import "react-quill/dist/quill.snow.css";

#root {
  height: 100%;
}

#root > .content-page {
  display: flex;
  min-height: calc(100vh - 157px);
}

header > .paddingHeaderNoAuth {
  min-height: 70px;
}

body {
  @apply text-base;
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btnGreen {
  @apply bg-hubGreen;
  @apply text-white;
  border: 1px solid #8ac04a;
  padding: 15px 12px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.btnGreenLt {
  @apply text-hubGreen bg-hubGreen bg-opacity-20;
  @apply hover:text-white hover:bg-opacity-100;
  border-radius: 3px;
}

.btnBlueLt {
  @apply text-hubBlueLt bg-hubBlueLt bg-opacity-20;
  @apply hover:text-white hover:bg-opacity-100;
  border-radius: 3px;
}

.btnRed {
  @apply text-hubRed bg-hubRed bg-opacity-20;
  @apply hover:text-white hover:bg-opacity-100;
  border-radius: 3px;
}

.btnFilter {
  @apply flex items-baseline;
  background-color: #f9f9f9;
  color: #8e8e8e;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 12px 15px;
}

table td,
table th {
  @apply border border-hubGrayLt p-4 text-hubGray text-left;
}

table th {
  @apply text-hubBlack font-normal;
}

table tbody tr {
  background: #f9f9f9;
}

table tbody tr:nth-child(odd) {
  background: #f4f4f4;
}

table thead tr {
  background: #f9f9f9;
}

table td {
  word-break: break-word;
}

body .ui.dimmer {
  background-color: rgba(0, 0, 0, 0.6);
}

/* disabled pre flight for unrooted tags  */

.disabledPreflight h1 {
  display: block;
  font-size: 39px !important;
  margin-top: 0.67em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h2 {
  display: block;
  font-size: 30px !important;
  margin-top: 0.83em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h3 {
  display: block;
  font-size: 28px !important;
  margin-top: 1em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h4 {
  display: block;
  font-size: 26px !important;
  margin-top: 1.33em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h5 {
  display: block;
  font-size: 20px !important;
  margin-top: 1.67em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight h6 {
  display: block;
  font-size: 18px !important;
  margin-top: 2.33em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight p {
  display: block;
  font-size: 14px !important;
  margin-top: 1em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

.disabledPreflight dl {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.disabledPreflight dd {
  display: block;
  margin-left: 40px;
}

.disabledPreflight hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.disabledPreflight figure {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

.disabledPreflight ol {
  display: block;
  font-size: 14px !important;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em !important;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.disabledPreflight ul {
  display: block;
  font-size: 14px !important;
  list-style-type: disc;
  margin-top: 1em !important;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.disabledPreflight li {
  display: list-item;
}

.disabledPreflight img {
  display: inline-block;
}
